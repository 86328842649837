"use client";

import { SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { FeaturedEventTile } from "./FeaturedEventTile";
import { RouterOutputs } from "~/trpc/react";

export const FeaturedEventsGrid = ({
  featuredEvents,
}: { featuredEvents: RouterOutputs["events"]["featured"] }) => {
  return (
    <Stack key={"category-popular"}>
      <Title order={2}>Popular events</Title>

      {featuredEvents?.length ? (
        <SimpleGrid spacing="xl" cols={{ base: 1, xs: 2, md: 3 }}>
          {featuredEvents.map((event) => (
            <FeaturedEventTile key={`${event}-${event.id}`} event={event} />
          ))}
        </SimpleGrid>
      ) : (
        <Text>There are no popular events right now</Text>
      )}
    </Stack>
  );
};
