import {
  EventStatus,
  event_images,
  event_ticket_types,
  events,
  hubs,
  producers,
  venues,
} from "@prisma/client";
import { Event } from "./events";
import { Producer } from "./producers";

export type HubType = "standard" | "catalogue";
export type LegacyHub = {
  id: string;
  producer_id: string;
  name: string;
  code: string;
  description: string;
  type: HubType;
  events: Event[];
  producer: Producer;
  created_at: Date;
  updated_at: Date;
  primary_color_hex: string;
  background_color_hex: string;
  text_color_hex: string;
  cover_image_url?: string;
  hub_events?: HubEvent[];
};

export type HubEvent = {
  id: string;
  hub_id: string;
  event_id: string;
  event: Event;
  hub: LegacyHub;
};

export type HubColors = {
  primary: string;
  background: string;
  foreground: string;
};
export const DefaultHubColors: HubColors = {
  primary: "#8fc740",
  background: "#f8f9fa",
  foreground: "#232323",
};

export type ProducerHub = hubs & {
  producer: producers;
  events: (events & {
    venue: venues | null;
    images: event_images[];
    ticket_types: event_ticket_types[];
  })[];
};

export const CATALOGUE_EVENT_STATUSES = [EventStatus.live];

export type Hub = ProducerHub & { producer: producers };
