"use client";

import { Button, Center, Container, Stack } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { Translate } from "react-i18nify";
import EventsSearchBox from "~/components/events/EventsSearchBox";
import { FeaturedEventsGrid } from "./FeaturedEventsGrid";
import { HomePageHero } from "./HomePageHero";
import { RouterOutputs } from "~/trpc/react";
import SuperJSON, { type SuperJSONResult } from "~/lib/superjson";

export const HomePage = ({
  featuredEvents: superJSONResult,
}: { featuredEvents: SuperJSONResult }) => {
  const featuredEvents =
    SuperJSON.deserialize<RouterOutputs["events"]["featured"]>(superJSONResult);
  const router = useRouter();

  const onSearchSubmit = (searchTerm?: string) =>
    searchTerm ? router.push(`/events?q=${searchTerm}`) : router.push("/events");

  return (
    <Container size="md">
      <HomePageHero />

      <Stack gap="xl">
        <EventsSearchBox onSubmit={onSearchSubmit} />
        <FeaturedEventsGrid featuredEvents={featuredEvents} />

        <Center>
          <Button component={Link} href="/events">
            <Translate value="events.see_all" />
          </Button>
        </Center>
      </Stack>
    </Container>
  );
};
