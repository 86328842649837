"use client";

import { Card, Group, Stack, Text, rem } from "@mantine/core";
import { DefaultHubColors } from "~/interfaces/root";
import { pickTextColor } from "~/utils/color_helpers";

type Props = {
  date: Date;
  colorHex?: string;
  borderColor?: string;
  hideTime?: boolean;
  timeZone: string;
};

const displayTimeZoneSuffix = (timeZone?: string) =>
  timeZone && timeZone !== Intl.DateTimeFormat().resolvedOptions().timeZone;
const timeZoneNameIfDifferent = (timeZone?: string) =>
  displayTimeZoneSuffix(timeZone) ? "short" : undefined;

const readableMonthFrom = (date: Date, timeZone: string) =>
  date
    .toLocaleDateString("en-NZ", {
      month: "short",
      timeZone: timeZone,
    } as Intl.DateTimeFormatOptions)
    .toLocaleUpperCase();

const readableTimeFrom = (date: Date, timeZone: string) =>
  date.toLocaleTimeString("en-NZ", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: timeZone,
  } as Intl.DateTimeFormatOptions);

const readableDateFrom = (date: Date, timeZone: string) =>
  date.toLocaleDateString("en-NZ", {
    day: "numeric",
    timeZone: timeZone,
  } as Intl.DateTimeFormatOptions);

const CalendarDate = ({
  date,
  colorHex = DefaultHubColors.primary,
  borderColor,
  hideTime,
  timeZone,
}: Props) => {
  const calendar = (
    <Card
      radius={4}
      p={0}
      style={{
        width: "3.5rem",
        height: "3.5rem",
        borderColor,
      }}
    >
      <Stack style={{ gap: 0, height: "100%", justifyContent: "center" }}>
        <Group
          py={1}
          align="center"
          w="100%"
          justify="center"
          style={{
            backgroundColor: colorHex,
            color: pickTextColor(colorHex, "white", "black"),
          }}
          data-testid="CalendarDate--dateBackground"
        >
          <Text size="xs" ta="center" suppressHydrationWarning>
            {readableMonthFrom(date, timeZone)}
          </Text>
        </Group>
        <Group
          grow
          style={{ width: "100%", height: "100%", backgroundColor: "white", color: "black" }}
        >
          <Text size="xl" ta="center" suppressHydrationWarning>
            {readableDateFrom(date, timeZone)}
          </Text>
        </Group>
      </Stack>
    </Card>
  );

  const time = hideTime ? null : (
    <Text ta="center" style={{ color: colorHex }} suppressHydrationWarning>
      {readableTimeFrom(date, timeZone)}
    </Text>
  );

  const timeZoneString = date
    .toLocaleDateString("en-NZ", {
      day: "2-digit",
      timeZone: timeZone,
      timeZoneName: timeZoneNameIfDifferent(timeZone),
    })
    .slice(4);
  const tz = timeZoneString ? (
    <Text size="xs" mt={rem(-6)} ta="center" style={{ color: colorHex }} suppressHydrationWarning>
      ({timeZoneString})
    </Text>
  ) : null;

  return (
    <Stack style={{ gap: 0, flexShrink: 0, maxWidth: "3.5rem", justifyContent: "center" }}>
      {calendar}
      {time}
      {tz}
    </Stack>
  );
};

export default CalendarDate;
