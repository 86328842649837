export class BaseError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "BaseError";

    const trueProto = new.target.prototype;
    Object.setPrototypeOf(this, trueProto);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, new.target);
    }
  }
}
