import { BaseError } from "./base_error";

export class ValidationError extends BaseError {
  errors: Record<string, string>;

  constructor(errors: Record<string, string>) {
    const combinedErrorMessage = Object.values(errors).join(", ");
    super(combinedErrorMessage);
    this.errors = errors;
  }
}
