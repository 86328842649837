"use client";

import { AspectRatio, Badge, Card, Group, Overlay, rem } from "@mantine/core";
import { event_images } from "@prisma/client";
import Image from "next/image";
import kiwiTicketLogo from "public/assets/images/kiwiticket_logo.svg";
import { Translate } from "react-i18nify";
import { EventAdminStatus, EventImage } from "~/interfaces";
import { EventImage as FirebaseEventImage } from "~/interfaces/firebase/firebaseEvent";

type Props = {
  event: { admin_status: EventAdminStatus | string };
  eventImage: EventImage | FirebaseEventImage | event_images | undefined;
};

export const EventCoverImage = ({ event, eventImage }: Props) => {
  const imageUrl = (eventImage as EventImage)?.url ?? (eventImage as FirebaseEventImage)?.imageUri;

  const processedImageUrl = imageUrl
    ?.replace(".jpg?alt=media", "_1200x675.jpg?alt=media")
    ?.replace(".png?alt=media", "_1200x675.png?alt=media");

  return (
    <AspectRatio ratio={16 / 9} style={{ flex: `0 0 ${rem(100)}` }}>
      <Card p={0} className="event-image" data-testid="event-image" shadow="sm">
        <Image
          fill
          sizes="(max-width: 768px) 100vw, 50vw"
          src={processedImageUrl ?? kiwiTicketLogo}
          alt="Event cover image"
          placeholder={eventImage?.blurhash ? "blur" : "empty"}
          blurDataURL={eventImage?.blurhash ?? undefined}
          style={{ objectFit: "cover" }}
        />

        {event.admin_status === "cancelled" && (
          <>
            <Overlay opacity={0.4} color="#000" zIndex="none" />
            <Group
              justify="center"
              align="center"
              style={{ height: "100%", zIndex: 1 }}
              data-testid="cancelled-badge"
            >
              <Badge size="lg" color="red" variant="filled">
                <Translate value="events.cancelled" />
              </Badge>
            </Group>
          </>
        )}
      </Card>
    </AspectRatio>
  );
};

export default EventCoverImage;
