"use client";

import { Group, Stack, Text, Title } from "@mantine/core";
import Image from "next/image";
import kiwiTicketLogo from "public/assets/images/kiwiticket_logo.svg";
import { Translate } from "react-i18nify";

export const HomePageHero = () => (
  <Group wrap="nowrap" mt={20} py={20}>
    <Image alt="KiwiTicket logo" src={kiwiTicketLogo} width={96} height={96} />

    <Stack gap={0}>
      <Title order={1}>
        <Translate value="application.name" />
      </Title>
      <Text>
        <span style={{ display: "inline-block" }}>Pay for the gigs you want to see,&nbsp;</span>
        <span style={{ display: "inline-block" }}>not someone else&apos;s fee</span>
      </Text>
    </Stack>
  </Group>
);
