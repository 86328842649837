import { Ticket, TicketState } from "./tickets";
import { DefaultMantineColor } from "@mantine/core";
import { refunds, transactions } from "@prisma/client";

export type Transaction = transactions & { tickets: Ticket[]; refunds: refunds[] };
export type TransactionStatus =
  | "pending"
  | "stale"
  | "complete"
  | "refunded"
  | "cancelled"
  | "error";

export enum RefundReason {
  duplicate = "duplicate",
  fraudulent = "fraudulent",
  requested_by_customer = "requested_by_customer",
  other = "other",
}

export const transactionStatusColorMapping: Record<TransactionStatus, DefaultMantineColor> = {
  pending: "gray",
  stale: "gray",
  complete: "lime",
  refunded: "indigo",
  cancelled: "gray",
  error: "red",
};

const ticketStateToTransactionStatus: Record<TicketState, TransactionStatus> = {
  [TicketState.Refunded]: "refunded",
  [TicketState.InCart]: "pending",
  [TicketState.Purchased]: "complete",
  [TicketState.Exchanged]: "complete",
  [TicketState.Stale]: "stale",
  [TicketState.Removed]: "cancelled",
  // doesn't apply to tickets in a transaction
  [TicketState.Recalled]: "error",
};

const mostInformationalTicketStatusOrder = [
  TicketState.Refunded,
  TicketState.InCart,
  TicketState.Purchased,
  TicketState.Stale,
  TicketState.Removed,
  // last as it doesn't apply to tickets in a transaction
  TicketState.Recalled,
];

export const statusOf = (transaction: Transaction): TransactionStatus => {
  if (transaction.tickets.some((ticket) => ticket.state === TicketState.Purchased))
    return "complete";

  const orderedStatuses = transaction.tickets
    .map((ticket) => ticket.state as TicketState)
    .sort(
      (a, b) =>
        mostInformationalTicketStatusOrder.indexOf(a) -
        mostInformationalTicketStatusOrder.indexOf(b),
    );

  return ticketStateToTransactionStatus[orderedStatuses[0]] ?? "error";
};
