"use client";

import { Button, Loader, TextInput } from "@mantine/core";
import { useRef } from "react";
import classes from "./EventsSearchBox.module.css";

type Props = {
  searchTerm?: string | null;
  className?: string;
  onSubmit?: (searchTerm?: string) => void;
  submitOnBlur?: boolean;
  defaultValue?: string;
  isLoading?: boolean;
};

const EventsSearchBox = ({ searchTerm, onSubmit, submitOnBlur, isLoading }: Props) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const inputSearchQuery = searchInputRef.current?.value?.trim() || undefined;
    if (onSubmit) onSubmit(inputSearchQuery);
  };

  return (
    <form onSubmit={onFormSubmit} className={classes.form} data-testid="search">
      <TextInput
        autoFocus
        name="event-search-input"
        role="searchbox"
        placeholder="Search events"
        defaultValue={searchTerm ?? undefined}
        ref={searchInputRef}
        onChange={(e) => e.target.value === "" && onFormSubmit(e)}
        onBlur={(e) => submitOnBlur && onFormSubmit(e)}
        rightSection={isLoading && <Loader size="xs" />}
        className={classes.searchInput}
      />

      <Button type="submit" className={classes.searchButton}>
        Search
      </Button>
    </form>
  );
};

export default EventsSearchBox;
