export function pickTextColor(bgColor: string, lightColor: string, darkColor: string) {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = Number.parseInt(color.substring(0, 2), 16);
  const g = Number.parseInt(color.substring(2, 4), 16);
  const b = Number.parseInt(color.substring(4, 6), 16);

  const uiColors = [r / 255, g / 255, b / 255];
  const c = uiColors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  });

  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

export function lightenHexColor(hexColor: string, amount: number) {
  return getTintedColor(hexColor, amount);
}

export function getTintedColor(c: string, v: number) {
  const color = c.charAt(0) === "#" ? c.substring(1, 7) : c;

  const rgb = Number.parseInt(color, 16);
  let r = Math.abs(((rgb >> 16) & 0xff) + v);
  if (r > 255) r = r - (r - 255);
  let g = Math.abs(((rgb >> 8) & 0xff) + v);
  if (g > 255) g = g - (g - 255);
  let b = Math.abs((rgb & 0xff) + v);
  if (b > 255) b = b - (b - 255);
  let red = Number(r < 0 || Number.isNaN(r)) ? "0" : (r > 255 ? 255 : r).toString(16);
  if (red.length === 1) red = `0${r}`;
  let green = Number(g < 0 || Number.isNaN(g)) ? "0" : (g > 255 ? 255 : g).toString(16);
  if (green.length === 1) green = `0${g}`;
  let blue = Number(b < 0 || Number.isNaN(b)) ? "0" : (b > 255 ? 255 : b).toString(16);
  if (blue.length === 1) blue = `0${b}`;
  return `#${red}${green}${blue}`;
}
