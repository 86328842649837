import { z } from "zod";
import { TermsAcceptanceDates } from "./user_terms";

export type CreateUserProps = {
  email: string;
  password: string;
  given_name?: string;
  family_name?: string;
};

export type RegisterUserProps = CreateUserProps & TermsAcceptanceDates & { is_guest: boolean };

export const newUserSchema = z.object({
  user: z.object({
    email: z.string().email(),
    password: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
  }),
  terms: z.object({
    customerTermsAcceptedAt: z.date().optional(),
    producerTermsAcceptedAt: z.date().optional(),
    privacyNoticeAcceptedAt: z.date(),
  }),
  continueUrl: z.string().optional(),
});

export type NewUserRequest = z.infer<typeof newUserSchema>;
